import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser ] = useState(() => {
        const savedUser  = localStorage.getItem('user');
        return savedUser  ? JSON.parse(savedUser ) : null;
    });

    const getSystemTheme = () => {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    };

    const [theme, setTheme] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme || getSystemTheme(); // Ustaw domyślny motyw na systemowy
    });

    useEffect(() => {
        localStorage.setItem('theme', theme); // Zapisz motyw w localStorage
        document.body.className = theme; // Zmień klasę ciała na podstawie motywu
    }, [theme]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const response = await axios.get(`https://gymsocial.pl:3001/api/users/${user.uid}`);
                    const userData = response.data;
                    const userInfo = { uid: user.uid, email: user.email, nick: userData.nick };
                    setUser (userInfo);
                    localStorage.setItem('user', JSON.stringify(userInfo)); // Zapisz użytkownika w localStorage
                } catch (error) {
                    console.error('Błąd podczas pobierania danych użytkownika:', error);
                }
            } else {
                setUser (null);
                localStorage.removeItem('user'); // Usuń użytkownika z localStorage
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser , theme, setTheme }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser  = () => useContext(UserContext);
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser  } from '../context/UserContext';
import { Link } from 'react-router-dom';
import '../styles/NotificationsPage.css';

const NotificationsPage = () => {
    const { user } = useUser ();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/notifications/${user.uid}`);
                setNotifications(response.data);
            } catch (error) {
                console.error('Błąd podczas pobierania powiadomień:', error);
            }
        };

        if (user) {
            fetchNotifications();
        }
    }, [user]);

    return (
        <div className="notifications-page">
            <h1>Powiadomienia</h1>
            {notifications.length === 0 ? (
                <p>Brak nowych powiadomień.</p>
            ) : (
                <ul>
                    {notifications.map(notification => (
                        <li key={notification.id}>
                            <Link to={`/profile/${notification.senderId}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                                {notification.senderProfilePic && (
                                    <img
                                        src={`https://gymsocial.pl:3001/profilePic/${notification.senderProfilePic}`}
                                        alt={`${notification.senderNick}'s profile`}
                                        style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                                    />
                                )}
                                <strong>{notification.message}</strong>
                                <span style={{ float: 'right', color: '#888', marginLeft: 'auto' }}>
                                    {new Date(notification.createdAt).toLocaleString()}
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default NotificationsPage;
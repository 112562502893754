const timeAgo = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    const intervals = [
        { value: 31536000, text: 'rok' },
        { value: 2592000, text: 'miesiąc' },
        { value: 604800, text: 'tydzień' },
        { value: 86400, text: 'dzień' },
        { value: 3600, text: 'godzina' },
        { value: 60, text: 'minuta' },
        { value: 1, text: 'sekunda' },
    ];

    for (const interval of intervals) {
        const count = Math.floor(seconds / interval.value);
        if (count >= 1) {
            // Handle pluralization
            let text = interval.text;
            if (count > 1 && count < 5) {
                if (text === 'rok') text = 'lata';
                else if (text === 'miesiąc') text = 'miesiące';
                else if (text === 'tydzień') text = 'tygodnie';
                else if (text === 'dzień') text = 'dni';
                else if (text === 'godzina') text = 'godziny';
                else if (text === 'minuta') text = 'minuty';
                else if (text === 'sekunda') text = 'sekundy';
            }
            if (count >= 5) {
                if (text === 'rok') text = 'lat';
                else if (text === 'miesiąc') text = 'miesięcy';
                else if (text === 'tydzień') text = 'tygodni';
                else if (text === 'dzień') text = 'dni';
                else if (text === 'godzina') text = 'godzin';
                else if (text === 'minuta') text = 'minut';
                else if (text === 'sekunda') text = 'sekund';
            }
            return `${count} ${text} temu`;
        }
    }
    return 'teraz'; // If the time difference is less than a second
};

export default timeAgo; // Corrected export statement
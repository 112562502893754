import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useUser  } from '../context/UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser , faCog } from '@fortawesome/free-solid-svg-icons';
import '../styles/ChatPage.css';
import { handleUnblockUser, handleBlockUser } from './inne/block'

const ChatPage = () => {
    const { user } = useUser ();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [user1Id, setUser1Id] = useState(null);
    const [user2Id, setUser2Id] = useState(null);
    const [otherId, setotherId] = useState(null);
    const [isTyping, setIsTyping] = useState(false); // Nowy stan do śledzenia pisania
    const navigate = useNavigate();
    const { conversationId } = useParams();
    const ws = useRef(null); // Ref do WebSocket
    const [offset, setOffset] = useState(0); // Stan do przechowywania offsetu
    const limit = 20; // Liczba wiadomości do załadowania na raz
    const messageListRef = useRef(null); // Ref do listy wiadomości
    const [isBlocked, setIsBlocked] = useState(false);
    const [isBlockedByCurrentUser , setIsBlockedByCurrentUser ] = useState(false);
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const [otherUserProfilePic, setOtherUserProfilePic] = useState(null); // Zmienna dla zdjęcia profilowego
    const [otherUserNick, setOtherUserNick] = useState(''); // Zmienna dla nicku
    

    useEffect(() => {
        messages.forEach(message => {
            if (!message.isRead && message.receiverId === user.uid) {
                fetchMessages();
                handleMessageRead(message.id);
            }
        });
    }, [messages, user.uid]);
    
    const handleMessageRead = async (messageId) => {
        if (!messageId) {
            console.error('messageId jest undefined');
            return; // Zakończ, jeśli messageId jest undefined
        }
    
        try {
            await axios.put(`https://gymsocial.pl:3001/api/messages/${messageId}/read`);
            // Zaktualizuj stan wiadomości, aby oznaczyć jako odczytane
            setMessages(prevMessages => 
                prevMessages.map(message => 
                    message.id === messageId ? { ...message, isRead: true } : message
                )
            );
        } catch (error) {
            console.error('Błąd podczas oznaczania wiadomości jako odczytanej:', error);
        }
    };

    useEffect(() => {
        const fetchConversationDetails = async () => {
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/messages/conversation/${conversationId}`);
                setUser1Id(response.data.user1Id);
                setUser2Id(response.data.user2Id);
                setOtherUserProfilePic(response.data.user2ProfilePic); // Ustaw zdjęcie profilowe drugiego użytkownika
                setOtherUserNick(response.data.user2Nick);
                if (response.data.user1Id === user.uid) {
                    setOtherUserNick(response.data.user2Nick); // Ustaw nick drugiej osoby
                } else {
                    setOtherUserNick(response.data.user1Nick); // Ustaw nick drugiej osoby
                }
                if (response.data.user1Id === user.uid) {
                    setOtherUserProfilePic(response.data.user2ProfilePic); // Ustaw zdjęcie profilowe drugiej osoby
                } else {
                    setOtherUserProfilePic(response.data.user1ProfilePic); // Ustaw zdjęcie profilowe drugiej osoby
                }
            } catch (error) {
                console.error('Błąd podczas pobierania szczegółów konwersacji:', error);
            }
        };

        fetchConversationDetails();
    }, [conversationId]);

    useEffect(() => {
        fetchMessages1();
    }, [conversationId, navigate, user.uid, offset]);

    useEffect(() => {
        // Inicjalizacja WebSocket
        ws.current = new WebSocket('wss://gymsocial.pl:3001/ws');
    
        ws.current.onopen = () => {
            console.log('WebSocket connected');
            // Rejestracja użytkownika
            ws.current.send(JSON.stringify({ type: 'register', userId: user.uid }));
        };
    
    
        ws.current.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'typing') {
                setIsTyping(true);
                setTimeout(() => setIsTyping(false), 20000);
            } else if (message.type === 'messageRead') {
                // Oznacz wiadomość jako odczytaną
                setMessages(prevMessages => 
                    prevMessages.map(msg => 
                        msg.id === message.messageId ? { ...msg, isRead: true } : msg
                    )
                );
            } else {
                // Dodaj wiadomość do stanu natychmiast
                setMessages(prevMessages => [...prevMessages, message]);
                setIsTyping(false);
                fetchMessages();
            }
        };
    
        ws.current.onclose = () => {
            console.log('WebSocket disconnected');
        };
    
        return () => {
            ws.current.close();
        };
    }, []);
    
    const fetchMessages1 = async () => {
        if (!conversationId) return;
    
        try {
            const response = await axios.get(`https://gymsocial.pl:3001/api/messages/${conversationId}`, {
                params: { userId: user.uid, limit, offset }
            });
    
            const messagesWithUserData = await Promise.all(response.data.map(async (message) => {
                const userResponse = await axios.get(`https://gymsocial.pl:3001/api/users/${message.senderId}`);
                return {
                    ...message,
                    senderNick: userResponse.data.nick,
                    senderProfilePic: userResponse.data.profilePic
                };
            }));
    
            // Zapisz aktualną pozycję scrolla
            const messageList = messageListRef.current;
            const scrollPosition = messageList.scrollTop; 
    
            // Dodaj nowe wiadomości
            setMessages(prevMessages => [...prevMessages, ...messagesWithUserData]);
    
            // Przywróć pozycję scrolla
            messageList.scrollTop = scrollPosition;
    
        } catch (error) {
            if (error.response && error.response.status === 403) {
                alert('Nie masz dostępu do tej konwersacji.');
                navigate('/messages');
            } else {
                console.error('Błąd podczas pobierania wiadomości:', error);
            }
        }
    };
    useEffect(() => {
        const messageList = messageListRef.current;
        if (messageList) {
            messageList.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (messageList) {
                messageList.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    const fetchMessages = async () => {
        if (!conversationId) return;
    
        try {
            const response = await axios.get(`https://gymsocial.pl:3001/api/messages/${conversationId}`, {
                params: { userId: user.uid }
            });
    
            const messagesWithUserData = await Promise.all(response.data.map(async (message) => {

                const userResponse = await axios.get(`https://gymsocial.pl:3001/api/users/${message.senderId}`);
                return {
                    ...message,
                    senderNick: userResponse.data.nick,
                    senderProfilePic: userResponse.data.profilePic
                };
            }));
    
            console.log('Pobrane wiadomości:', messagesWithUserData); // Dodaj log
            setMessages(messagesWithUserData);
            scrollToBottom();
        } catch (error) {
            if (error.response && error.response.status === 403) {
                alert('Nie masz dostępu do tej konwersacji.');
                navigate('/messages');
            } else {
                console.error('Błąd podczas pobierania wiadomości:', error);
            }
        }
    };
    const handleScroll = () => {
        const messageList = messageListRef.current;
        const isAtBottom = messageList.scrollHeight + messageList.scrollTop <= messageList.clientHeight + 10;// Tolerancja 10px
        if (isAtBottom) {
            setOffset(prevOffset => prevOffset + limit); // Zwiększ offset, aby pobrać więcej wiadomości
        }
    };
    const scrollToBottom = () => {
        const messageList = document.querySelector('.chat-page-message-list');
        if (messageList) {
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;
    
        // Upewnij się, że receiverId jest zdefiniowane
        const receiverId = user1Id === user.uid ? user2Id : user1Id;
    
        // Sprawdź, czy użytkownik jest zablokowany
        const isBlockedResponse = await axios.get(`https://gymsocial.pl:3001/api/users/${receiverId}/blocked`, {
            params: { userId: user.uid }
        });
    
        if (isBlockedResponse.data.blocked) {
            alert('Zostałeś zablokowany przez tę osobę. Nie możesz wysłać wiadomości.');
            return; // Zakończ, jeśli użytkownik jest zablokowany
        }
    
        // Logika wysyłania wiadomości
        if (ws.current) {
            const messageData = {
                senderId: user.uid,
                receiverId: receiverId,
                content: newMessage,
                conversationId: conversationId,
            };
            ws.current.send(JSON.stringify(messageData));
        }
    
        setNewMessage(''); // Wyczyść pole wiadomości
        fetchMessages(); // Odśwież listę wiadomości
    };


    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
        
        // Wysyłanie informacji o pisaniu
        if (ws.current) {
            ws.current.send(JSON.stringify({
                type: 'typing',
                senderId: user.uid,
                conversationId: conversationId,
            }));
        }
    };

    useEffect(() => {
        checkIfBlocked(conversationId);
    }, [user1Id, user.uid, conversationId]);

    const unBlockUser  = () => {
        handleUnblockUser (otherId, user.uid, setIsBlockedByCurrentUser ); // Pass uid and userId as arguments
    };

    const toggleSettingsMenu = () => {
        setShowSettingsMenu(prev => !prev); // Przełącz widoczność menu ustawień
    };

    const BlockUser  = async () => {
        await handleBlockUser (otherId, user.uid, setIsBlockedByCurrentUser );
        setIsBlockedByCurrentUser(true)
        setShowSettingsMenu(false); // Ukryj menu po zablokowaniu
    };
    const checkIfBlocked = async (conversationId) => {
        try {
            const response = await axios.get(`https://gymsocial.pl:3001/api/messages/conversation/${conversationId}`);
            const fetchedUser1Id = response.data.user1Id;
            const fetchedUser2Id = response.data.user2Id;

            setUser1Id(fetchedUser1Id);
            setUser2Id(fetchedUser2Id);
    
            const otherIds = fetchedUser1Id === user.uid ? fetchedUser2Id : fetchedUser1Id; // Upewnij się, że ID są różne
            setotherId(otherIds);
            // Sprawdź, czy aktualny użytkownik (user.uid) jest zablokowany przez drugiego użytkownika (otherId)
            const isBlockedResponse = await axios.get(`https://gymsocial.pl:3001/api/users/${user.uid}/blocked`, {
                params: { userId: otherIds } // Użyj otherId jako ID użytkownika, którego blokada jest sprawdzana
            });
            setIsBlocked(isBlockedResponse.data.blocked);
    
            // Sprawdź, czy aktualny użytkownik (otherId) zablokował drugiego użytkownika (user.uid)
            const blockedResponse = await axios.get(`https://gymsocial.pl:3001/api/users/${otherIds}/blockedByMe`, {
                params: { userId: user.uid } // Użyj user.uid jako ID użytkownika, którego blokada jest sprawdzana
            });
            setIsBlockedByCurrentUser (blockedResponse.data.blocked);
            console.log(blockedResponse.data.blocked);
        } catch (error) {
            console.error('Błąd podczas sprawdzania blokady:', error);
        }
    };

    return (
        <div className="chat-page">
            <div className="chat-settings">
                <div className="chat-header" onClick={toggleSettingsMenu}>
                    {otherUserProfilePic ? (
                        <img
                            src={`https://gymsocial.pl:3001/profilePic/${otherUserProfilePic}`} // Upewnij się, że to jest poprawna ścieżka
                            alt={`${otherUserNick}'s profile`}
                            style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '10px' ,maxHeight: '600px',maxWidth: '100%',objectFit: 'cover' }}
                        />
                    ) : (
                        <img
                            src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`}
                            style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '10px' ,maxHeight: '600px',maxWidth: '100%',objectFit: 'cover' }}
                        />
                    )}
                    <span className="chat-page-user-nick" >
                        {otherUserNick} 
                    </span> {/* Wyświetl nick drugiej osoby */}
                </div>
                {showSettingsMenu && (
                    <div className="settings-menu active">
                        <button onClick={BlockUser }>Zablokuj</button>
                    </div>
                )}
            </div>
            <div className="chat-page-message-list" ref={messageListRef}>
                {isTyping && <div className="typing-indicator">Druga osoba pisze...</div>}
                {messages.map((message, index) => (
                    <div key={index} className={`chat-page-message ${message.senderId === user.uid ? 'sent' : 'received'}`}>
                        <div className="chat-page-message-header">
                            {message.senderProfilePic ? (
                                <img
                                    src={`https://gymsocial.pl:3001/profilePic/${message.senderProfilePic}`}
                                    alt={`${message.senderNick}'s profile`}
                                    style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' ,maxHeight: '600px',maxWidth: '100%',objectFit: 'cover' }}
                                />
                            ) : (
                                <img
                                    src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`}
                                    style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' ,maxHeight: '600px',maxWidth: '100%',objectFit: 'cover' }}
                                />
                            )}
                            <strong>{message.senderNick}</strong>
                            <span 
                                className="chat-page-timestamp"
                                style={{ marginLeft: '10px', fontSize: '0.9em', color: '#888' }}
                            >
                                {new Date(message.createdAt).toLocaleString()}
                            </span>
                        </div>
                        <p>{message.content}</p>
                        {message.isRead ? <span className="read-status">Odczytano</span> : <span className="unread-status">Nieodczytano</span>}
                    </div>
                ))}
            </div>
            {isBlockedByCurrentUser  ? (
                <div className="blocked-message">
                    <p>Zablokowałeś tego użytkownika.</p>
                    <button style={{ display: 'block', margin: '0 auto' }} onClick={unBlockUser }>Odblokuj</button>
                </div>
            ) : isBlocked ? (
                <div className="blocked-message">
                    <p>Zostałeś zablokowany przez tę osobę. Nie możesz wysłać wiadomości.</p>
                </div>            
            ) : (
                <form onSubmit={handleSendMessage} className="chat-page-message-form">
                    <input 
                        type="text" 
                        placeholder="Wpisz wiadomość..." 
                        value={newMessage} 
                        onChange={handleInputChange}
                    />
                    <button type="submit">Wyślij</button>
                </form>
            )}
        </div>
    );
};
export default ChatPage;
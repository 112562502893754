import React, { useState } from 'react';
import { auth } from '../firebase';
import { getAuth, createUserWithEmailAndPassword, deleteUser  } from 'firebase/auth';
import axios from 'axios';
import { useUser  } from '../context/UserContext'; // Importuj kontekst użytkownika
import { useNavigate } from 'react-router-dom';
import '../styles/RegisterPage.css';
import Header from './Header'; // Importuj Header

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nick, setNick] = useState('');
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const cookieConsent = localStorage.getItem('cookieConsent');
  const { user } = useUser (); // Uzyskaj informacje o użytkowniku

  if (user) {
      // Jeśli użytkownik jest już zalogowany, przekieruj go na stronę główną
      navigate('/home');
  }

  if (!cookieConsent) {
      return (
          <div>
              <h2>Musisz zaakceptować pliki cookie, aby się zarejestrować.</h2>
              <p>Proszę zaakceptować pliki cookie, aby kontynuować.</p>
          </div>
      );
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    let userCredential; // Deklaracja zmiennej na zewnątrz bloku try
    try {
        // Upload zdjęcia profilowego
        const formData = new FormData();
        formData.append('image', image);

        // Rejestracja użytkownika w Firebase
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const userId = userCredential.user.uid;

        // Zapisanie danych użytkownika do bazy danych
        await axios.post('https://gymsocial.pl:3001/api/users/register-user', {
            userId,
            nick,
            email,
            image,
        });

        // Jeśli zapisanie danych użytkownika zakończyło się sukcesem
        setEmail('');
        setPassword('');
        setNick('');
        setImage(null);
        setError('');
        alert('Rejestracja zakończona sukcesem!');
        navigate('/home');
    } catch (err) {
        if (userCredential && userCredential.user) {
            const user = userCredential.user; // Uzyskaj instancję użytkownika
            console.log('Użytkownik utworzony w Firebase:', user);
            // Usuń użytkownika z Firebase
            await deleteUser (user).catch(deleteError => {
                console.error('Błąd podczas usuwania użytkownika z Firebase:', deleteError);
            });
            console.log('Użytkownik usunięty z Firebase');
        }

        // Ustaw komunikat o błędzie
        if (err.response && err.response.status === 400) {
            setError(err.response.data.message); // Ustaw komunikat z odpowiedzi serwera
        } else {
            const errorMessages = {
                'auth/email-already-in-use': 'E-mail jest już używany przez innego użytkownika.',
                'auth/weak-password': 'Hasło jest zbyt słabe. Proszę wybrać silniejsze hasło.',
                'auth/invalid-email': 'Nieprawidłowy adres e-mail. Proszę wprowadzić poprawny adres e-mail.',
            };

            setError(errorMessages[err.code] || `Wystąpił błąd: ${err.message}`);
        }
    }
};
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="register-page" style={{ marginTop: '80px' }}>
      <Header />
      <h1>Rejestracja</h1>
      <form onSubmit={handleRegister}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Hasło"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Nick"
          value={nick}
          onChange={(e) => setNick(e.target.value)}
          required
        />
        <button type="submit">Zarejestruj się</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default RegisterPage;
import axios from 'axios';

export const handleBlockUser  = async (uid, userId) => {
    try {
        await axios.post(`https://gymsocial.pl:3001/api/users/${uid}/block`, { userId });
        alert('Użytkownik został zablokowany.');
    } catch (error) {
        console.error('Błąd podczas blokowania użytkownika:', error);
        alert('Wystąpił błąd podczas blokowania użytkownika.');
    }
};

export const handleUnblockUser  = async (otherId, userIds, setIsBlockedByCurrentUser ) => {
    try {
        console.log(otherId)
        await axios.delete(`https://gymsocial.pl:3001/api/users/${otherId}/block`, {
            data: { userId: userIds }
        });
        setIsBlockedByCurrentUser (false); // Ustaw stan na false po odblokowaniu
        alert('Użytkownik został odblokowany.');
    } catch (error) {
        console.error('Błąd podczas odblokowywania użytkownika:', error);
    }
};
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Header.css'; // Importuj style

const Header = () => {
    const navigate = useNavigate();

    return (
        <header className="app-header">
            <h1 onClick={() => navigate('/')} className="app-title">GymSocial</h1>
            <nav className="app-nav">
                <span onClick={() => navigate('/login')} className="nav-link">Logowanie</span>
                <span onClick={() => navigate('/register')} className="nav-link">Rejestracja</span>
            </nav>
        </header>
    );
};

export default Header;
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useUser  } from '../context/UserContext';
import '../styles/PostList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComment, faUser , faEllipsisV } from '@fortawesome/free-solid-svg-icons'; // Dodaj ikonę trzech kropek
import { Link, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import timeAgo from './inne/timeAgo';
import { handleUnlikePost, handleLikePost } from './inne/likePosts';

const PostList = () => {
    const [posts, setPosts] = useState([]);
    const { user } = useUser ();
    const [newComment, setNewComment] = useState({});
    const [visibleComments, setVisibleComments] = useState({});
    const postRefs = useRef([]);
    const [offset, setOffset] = useState(0);
    const limit = 8; // Liczba postów do pobrania na raz
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showOnlyFollowed, setShowOnlyFollowed] = useState(false);
    const [comments, setComments] = useState([]);
    const navigate = useNavigate(); // Inicjalizuj useNavigate

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1385) {
                // Ustaw wszystkie komentarze jako widoczne
                const allVisible = {};
                posts.forEach(post => {
                    allVisible[post.id] = true;
                });
                setVisibleComments(allVisible);
            } else {
                // Resetuj widoczność komentarzy, jeśli ekran jest mniejszy
                setVisibleComments({});
            }
        };

        handleResize(); // Sprawdź rozmiar na początku
        window.addEventListener('resize', handleResize); // Nasłuchuj zmian rozmiaru

        return () => {
            window.removeEventListener('resize', handleResize); // Oczyść nasłuchiwacz
        };
    }, [posts]);

    useEffect(() => {
        fetchPosts();
    }, [offset, user, showOnlyFollowed]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [posts]);

    const fetchPosts = async () => {
        try {
            console.log(user.uid)
            const response = await axios.get(`https://gymsocial.pl:3001/api/posts`, {
                params: {
                    limit: limit,
                    offset: offset,
                    followed: showOnlyFollowed,
                    userId: user.uid
                }
            });
    
            if (response.data.length === 0) {
                return; // Zakończ, jeśli nie ma nowych postów
            }
    
            const postsWithLikes = await Promise.all(response.data.map(async (post) => {
                const likesResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/${post.id}/likes`);
                const likedResponse = user ? await axios.get(`https://gymsocial.pl:3001/api/posts/${post.id}/liked`, { params: { userId: user.uid } }) : { data: { liked: false } };
                const commentsResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/${post.id}/comments`);
    
                // Zaktualizowane pobieranie komentarzy z informacją o polubieniu
                const commentsWithLikes = await Promise.all(commentsResponse.data.map(async (comment) => {
                    const commentLikesResponse = await axios.get(`https://gymsocial.pl:3001/api/comments/${comment.id}/likes`);
                    const likedCommentResponse = user ? await axios.get(`https://gymsocial.pl:3001/api/comments/${comment.id}/liked`, { params: { userId: user.uid } }) : { data: { liked: false } };
                    
                    return {
                        ...comment,
                        likesCount: commentLikesResponse.data.likesCount,
                        liked: likedCommentResponse.data.liked // Dodaj informację o polubieniu komentarza
                    };
                }));
    
                return {
                    ...post,
                    likesCount: likesResponse.data.likesCount,
                    liked: likedResponse.data.liked,
                    comments: commentsWithLikes
                };
            }));
    
            setPosts(prevPosts => {
                const existingPostIds = new Set(prevPosts.map(post => post.id));
                const newPosts = postsWithLikes.filter(post => !existingPostIds.has(post.id));
                return [...prevPosts, ...newPosts];
            });
        } catch (error) {
            console.error('Błąd podczas pobierania postów:', error);
        }
    };

    const handleToggleFollowed = () => {
        setShowOnlyFollowed(prev => !prev); // Zmień stan
        setOffset(0); // Resetuj offset, aby załadować posty od początku
        fetchPosts(); // Ponownie załaduj posty
    };

    const handleScroll = debounce(() => {
        const scrollTop = document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        const isAtBottom = windowHeight + scrollTop >= documentHeight - 50; // Tolerancja 50px

        if (isAtBottom) {
            if (posts.length % limit === 0 && posts.length > 0) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, 200);

    const toggleCommentsVisibility = (postId) => {
        setVisibleComments(prev => ({
            ...prev,
            [postId]: !prev[postId],
        }));
    };

    const handleAddComment = async (postId) => {
        if (!user) {
            alert('Musisz być zalogowany, aby dodać komentarz.');
            return;
        }
    
        try {
            // Dodaj nowy komentarz do serwera
            const response = await axios.post(`https://gymsocial.pl:3001/api/posts/${postId}/comment`, {
                userId: user.uid,
                content: newComment[postId],
            });
    
            // Po dodaniu komentarza, pobierz aktualne komentarze
            const updatedComments = await fetchComments(postId);
    
            // Zaktualizuj stan postów
            setPosts(prevPosts =>
                prevPosts.map(post =>
                    post.id === postId
                        ? { ...post, comments: updatedComments } // Zaktualizuj komentarze
                        : post
                )
            );
    
            // Wyczyść pole komentarza
            setNewComment(prev => ({ ...prev, [postId]: '' }));
        } catch (err) {
            console.error('Błąd podczas dodawania komentarza:', err);
        }
    };
    
    // Funkcja do pobierania komentarzy
    const fetchComments = async (postId) => {
        try {
            const response = await axios.get(`https://gymsocial.pl:3001/api/posts/${postId}/comments`);
            const commentsWithLikes = await Promise.all(response.data.map(async (comment) => {
                const likedResponse = await axios.get(`https://gymsocial.pl:3001/api/comments/${comment.id}/liked`, {
                    params: { userId: user.uid }
                });
                return {
                    ...comment,
                    liked: likedResponse.data.liked // Dodaj informację o polubieniu
                };
            }));
            return commentsWithLikes;
        } catch (error) {
            console.error('Błąd podczas pobierania komentarzy:', error);
        }
    };
    const handleLikeComment = async (postId, commentId) => {
        try {
            await axios.post(`https://gymsocial.pl:3001/api/comments/${commentId}/like`, { userId: user.uid });
            updateCommentLikes(postId, commentId);
            setPosts(prevPosts =>
                prevPosts.map(post =>
                    post.id === postId
                    ? {
                        ...post,
                        comments: post.comments.map(comment =>
                            comment.id === commentId
                            ? { ...comment, liked: true } // Ustawienie, że komentarz jest polubiony
                            : comment
                        )
                    }
                    : post
                )
            );
        } catch (error) {
            console.error('Błąd podczas polubienia komentarza:', error);
        }
    };
    
    const handleUnlikeComment = async (postId, commentId) => {
        try {
            await axios.delete(`https://gymsocial.pl:3001/api/comments/${commentId}/like`, { data: { userId: user.uid } });
            updateCommentLikes(postId, commentId);
            setPosts(prevPosts =>
                prevPosts.map(post =>
                    post.id === postId
                    ? {
                        ...post,
                        comments: post.comments.map(comment =>
                            comment.id === commentId
                            ? { ...comment, liked: false } // Ustawienie, że komentarz nie jest polubiony
                            : comment
                        )
                    }
                    : post
                )
            );
        } catch (error) {
            console.error('Błąd podczas anulowania polubienia komentarza:', error);
        }
    };

    const updateCommentLikes = async (postId, commentId) => {
        try {
            const response = await axios.get(`https://gymsocial.pl:3001/api/comments/${commentId}/likes`);
            setPosts(prevPosts =>
                prevPosts.map(post =>
                    post.id === postId
                    ? {
                        ...post,
                        comments: post.comments.map(comment =>
                            comment.id === commentId
                            ? { ...comment, likesCount: response.data.likesCount }
                            : comment
                        )
                    }
                    : post
                )
            );
        } catch (error) {
            console.error('Błąd podczas aktualizacji polubień komentarza:', error);
        }
    };
    const handlePostClick = (postId) => {
        navigate(`/posts/${postId}`); // Przejdź do strony posta
    }

    return (
        <div className="post-list">
            <button onClick={handleToggleFollowed}>
                {showOnlyFollowed ? 'Pokaż wszystkie posty' : 'Pokaż tylko posty od obserwowanych'}
            </button>
        {posts.map((post, index) => (
            <div className="post" key={post.id}>
                <div>
                    <div className="post-content-over">
                        <div className="post-content" ref={el => postRefs.current[index] = el}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {post.profilePic ? (
                                    <img
                                        src={`https://gymsocial.pl:3001/profilePic/${post.profilePic}`} // Upewnij się, że to jest poprawna ścieżka
                                        alt={`${post.nick}'s profile`}
                                        style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }} // Stylizacja zdjęcia profilowego
                                        />
                                    ) : (
                                        <img
                                        src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`} 
                                        style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }} />
                                    )}
                                <Link to={`/profile/${post.userId}`} style={{ textDecoration: 'none', color: 'inherit', fontSize: '1.2em' }}>
                                    <strong>{post.nick}</strong>
                                </Link>
                                <em style={{ marginLeft: '10px', fontSize: '0.9em', color: '#888' }}>{timeAgo(post.createdAt)}</em>
                            </div>
                            <FontAwesomeIcon 
                                        icon={faEllipsisV} 
                                        style={{ cursor: 'pointer' }} 
                                        onClick={() => handlePostClick(post.uuid)} // Dodaj obsługę kliknięcia
                                    />
                            <h2>{post.title}</h2>
                            {post.imageUrl && (
                                <img src={`https://gymsocial.pl:3001/postsPic/${post.imageUrl}`} alt={post.title} />
                            )}
                            <p>{post.content}</p>
                            <div>
                                <button className='post-list-button' onClick={post.liked ? () => handleUnlikePost(post.id, user, setPosts) : () => handleLikePost(post.id, user, setPosts)}>
                                    <FontAwesomeIcon icon={faHeart} style={{ color: post.liked ? 'red' : 'black' }} />
                                </button>
                                <span>{post.likesCount} polubień</span>
                                <button className='post-list-button' onClick={() => toggleCommentsVisibility(post.id)}>
                                    <FontAwesomeIcon icon={faComment} style={{ color: 'blue' }} />
                                </button>
                                <span>{post.comments.length} komentarzy</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comment-post" ref={el => postRefs.current[index] = el}>
                    <div>
                        {visibleComments[post.id] && (
                            <div className="comment-section" 
                                style={windowWidth >= 1385
                                    ? { maxHeight: `${postRefs.current[index]?.clientHeight * 0.85}px`, overflowY: 'auto', height: '100%' } 
                                    : { maxHeight: '500px', overflowY: 'auto' } // Ustaw maksymalną wysokość na 500px dla mniejszych ekranów
                                }>
                                <h3>Komentarze:</h3>
                                {post.comments.length > 0 ? (
                                    <div>
                                        {post.comments.map(comment => (
                                            <div className="comment" key={comment.id}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {comment.profilePic ? (
                                                        <img
                                                            src={`https://gymsocial.pl:3001/profilePic/${comment.profilePic}`}
                                                            alt={`${comment.nick}'s profile`}
                                                            style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`} 
                                                            style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }} />
                                                    )}
                                                    <Link to={`/profile/${comment.userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        <strong>{comment.nick}</strong>
                                                    </Link>
                                                    <em style={{ marginLeft: '10px', fontSize: '0.9em', color: '#888' }}>{timeAgo(comment.createdAt)}</em>
                                                </div>
                                                <p>{comment.content}</p>
                                                <div>
                                                    {comment.liked ? (
                                                        <button className='post-list-button' onClick={() => handleUnlikeComment(post.id, comment.id)}>
                                                            <FontAwesomeIcon icon={faHeart} style={{ color: 'red' }} />
                                                        </button>
                                                    ) : (
                                                        <button className='post-list-button' onClick={() => handleLikeComment(post.id, comment.id)}>
                                                            <FontAwesomeIcon icon={faHeart} style={{ color: 'black' }} />
                                                        </button>
                                                    )}
                                                    <span>{comment.likesCount} polubień</span>
                                                </div>
                                            </div>
                                        ))}
                                     </div>
                                    ) : (
                                        <p>Brak komentarzy.</p>
                                    )}
                                </div>
                            )}
                            {visibleComments[post.id] && (
                                <div className='post-list-add-comment'> 
                                    <input
                                        style={{width: '100%'}}
                                        type="text"
                                        placeholder="Dodaj komentarz..."
                                        value={newComment[post.id] || ''}
                                        onChange={(e) => setNewComment({ ...newComment, [post.id]: e.target.value })}
                                    />
                                    <button onClick={() => handleAddComment(post.id)} className='AddCommentButton'>
                                        Dodaj
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PostList;
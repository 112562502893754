import React, { useState, useEffect } from 'react';
import { useUser  } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Importuj auth
import '../styles/Layout.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEnvelope, faBell, faCog, faSignOutAlt, faPalette } from '@fortawesome/free-solid-svg-icons';

const Layout = ({ children }) => {
    const { user, setUser , theme, setTheme } = useUser (); // Dodaj setUser  do destrukturyzacji
    const navigate = useNavigate();
    const [showMoreMenu, setShowMoreMenu] = useState(false); // Stan do zarządzania widocznością menu
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Sprawdź rozmiar na początku

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLogout = async () => {
        try {
            await auth.signOut(); 
            setUser (null); 
            localStorage.removeItem('user'); // Usunięcie użytkownika z localStorage
            localStorage.removeItem('cookieConsent'); // Usunięcie zgody na cookies
            localStorage.removeItem('userToken'); // Usunięcie tokenu użytkownika
    
            // Usunięcie firebase-local-storage
            localStorage.removeItem('firebase-heartbeat-database');
            localStorage.removeItem('firebaseLocalStorageDb');
    
            // Usunięcie cookies
            document.cookie.split(";").forEach((c) => {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
            });
    
            // Odświeżenie strony
            window.location.reload(); // Odśwież stronę
        } catch (error) {
            console.error('Błąd podczas wylogowania:', error);
        }
    };

    const handleThemeChange = () => {
      setTheme(theme === 'light' ? 'dark' : 'light'); // Zmień motyw
    };
    return (
        <div className="home-page" style={{ display: 'flex' }}>
            <div className="sidebar">
                <h2>Menu</h2>
                <ul>
                    <li onClick={() => navigate('/home')}>
                        <FontAwesomeIcon icon={faHome} /> {!isMobile && 'Strona główna'}
                    </li>
                    <li onClick={() => navigate('/messages')}>
                        <FontAwesomeIcon icon={faEnvelope} /> {!isMobile && 'Wiadomości'}
                    </li>
                    <li onClick={() => navigate('/notifications')}>
                        <FontAwesomeIcon icon={faBell} /> {!isMobile && 'Powiadomienia'}
                    </li>
                    <li onClick={() => navigate('/gymstatspro')}>GymStatsPro</li>
                </ul>
                <li onClick={() => setShowMoreMenu(!showMoreMenu)}>
                    <FontAwesomeIcon icon={faCog} /> {!isMobile && 'Więcej'}
                </li>
                {showMoreMenu && (
                    <ul className="more-menu">
                        <li onClick={() => navigate('/settings')}>
                            <FontAwesomeIcon icon={faCog} /> {!isMobile && 'Ustawienia'}
                        </li>
                        <li onClick={handleThemeChange}>
                            <FontAwesomeIcon icon={faPalette} /> {!isMobile && 'Zmień wygląd'}
                        </li>
                        <li onClick={handleLogout}>
                            <FontAwesomeIcon icon={faSignOutAlt} /> {!isMobile && 'Wyloguj'}
                        </li>
                    </ul>
                )}
            </div>
            <div className="main-content" style={{ flex: 1, padding: '20px' }}>
                {children}
            </div>
        </div>
    );
};

export default Layout;
import axios from 'axios';
// Funkcje do polubienia i przestawania polubienia posta
const handleLikePost = async (postId, user, setPosts) => {
    try {
        const userId = user.uid;
        await axios.post(`https://gymsocial.pl:3001/api/posts/${postId}/like`, { userId });
        fetchLikesCount(postId, setPosts);
        setPosts(prevPosts =>
            prevPosts.map(post =>
                post.id === postId ? { ...post, liked: true } : post
            )
        );
    } catch (err) {
        console.error(err);
    }
};

const handleUnlikePost = async (postId, user, setPosts) => {
    try {
        const userId = user.uid;
        await axios.delete(`https://gymsocial.pl:3001/api/posts/${postId}/like`, { data: { userId } });
        fetchLikesCount(postId, setPosts);
        setPosts(prevPosts =>
            prevPosts.map(post =>
                post.id === postId ? { ...post, liked: false } : post
            )
        );
    } catch (err) {
        console.error(err);
    }
};

const fetchLikesCount = async (postId, setPosts) => {
    try {
        const response = await axios.get(`https://gymsocial.pl:3001/api/posts/${postId}/likes`);
        setPosts(prevPosts =>
            prevPosts.map(post =>
                post.id === postId ? { ...post, likesCount: response.data.likesCount } : post
            )
        );
    } catch (err) {
        console.error(err);
    }
};

// Eksport funkcji
export { fetchLikesCount, handleUnlikePost, handleLikePost };
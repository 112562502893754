import React from 'react';
import PostList from './PostList';
import { useUser  } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import '../styles/HomePage.css';

const HomePage = () => {
    const { user} = useUser (); // Dodaj setUser  do destrukturyzacji
    const navigate = useNavigate();

    const handleAddPost = () => {
        navigate('/create-post');
    };

    return (
        <div className="home-page" style={{ display: 'flex' }}>
            {/* Główna zawartość */}
            <div className="main-content" style={{ flex: 1, padding: '20px', marginLeft: '0px' }}>
                <h1>Witaj w GymSocial!</h1>
                {user && user.nick ? (
                    <h2>Witaj, {user.nick}!</h2>
                ) : (
                    <h2>Witaj, gościu!</h2>
                )}
                <p>To jest strona główna aplikacji GymSocial.</p>
                <p>Możesz przeglądać posty, dodawać nowe i wiele więcej!</p>

                <PostList />

                {user && (
                    <button
                        onClick={handleAddPost}
                        style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px',
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px',
                            fontSize: '24px',
                            backgroundColor: '#28a745',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                            padding: 'none',
                        }}
                    >
                        +
                    </button>
                )}
            </div>
        </div>
    );
};

export default HomePage;
import React from 'react';
import '../styles/LandingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faComments, faUser } from '@fortawesome/free-solid-svg-icons';

const features = [
  {
    icon: faPlus,
    title: 'Łatwe dodawanie postów',
    description: 'Dodawaj swoje osiągnięcia i inspiruj innych.',
  },
  {
    icon: faComments,
    title: 'Interakcja z innymi',
    description: 'Obserwuj innych użytkowników i komentuj ich posty.',
  },
  {
    icon: faUser,
    title: 'Personalizowany profil',
    description: 'Stwórz swój unikalny profil i dziel się swoimi zdjęciami.',
  },
];

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header className="landing-page-header">
        <h1 className="fade-in">Witaj w GymSocial!</h1>
        <p className="fade-in">Twoje miejsce do dzielenia się pasją do fitnessu.</p>
      </header>

      <section className="landing-page-features">
      <div className="landing-page-button-container fade-in">
          <a href="/register" className="landing-page-btn-reg">Zarejestruj się</a>
          <a href="/login" className="landing-page-btn-log">Zaloguj się</a>
        </div>
        <h2 className="fade-in">Nasze funkcje</h2>
        <div className="landing-page-features-grid">
          {features.map((feature, index) => (
            <div key={index} className="landing-page-feature fade-in">
              <FontAwesomeIcon icon={feature.icon} className="landing-page-feature-icon" />
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <footer className="landing-page-footer">
        <p>&copy; {new Date().getFullYear()} GymSocial. Wszelkie prawa zastrzeżone.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
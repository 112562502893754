import React, { useState } from 'react';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/ResetPasswordPage.css';
import Header from './Header'; // Importuj Header

const ResetPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Link do resetowania hasła został wysłany na Twój adres e-mail.');
            setError('');
        } catch (err) {
            setError(err.message);
            setMessage('');
        }
    };

    return (
        <div className="reset-password-page" style={{ marginTop: '80px' }}>
            <Header />
            <h1>Resetowanie hasła</h1>
            <form onSubmit={handleResetPassword}>
                <input
                    type="email"
                    placeholder="Wprowadź swój adres e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Wyślij link do resetowania hasła</button>
                {message && <p className="success-message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
            </form>
            <p onClick={() => navigate('/login')} className="back-to-login">Powrót do logowania</p>
        </div>
    );
};

export default ResetPasswordPage;
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useUser  } from '../context/UserContext'; // Importuj kontekst użytkownika

const PrivateRoute = ({ children }) => {
    const { user } = useUser (); // Uzyskaj informacje o użytkowniku

    return user ? children : <Navigate to="/" />;
};

export default PrivateRoute;
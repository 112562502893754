import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useUser  } from '../context/UserContext';
import '../styles/PostPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComment, faUser  } from '@fortawesome/free-solid-svg-icons';
import timeAgo from './inne/timeAgo';
import { handleUnlikePost, handleLikePost } from './inne/likePosts';

const PostPage = () => {
    const { uuid } = useParams();
    const { user } = useUser ();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [error, setError] = useState('');
    const [reportDescription, setReportDescription] = useState('');
    const [reportCategory, setReportCategory] = useState('');
    const [isReportFormVisible, setIsReportFormVisible] = useState(false);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/posts/uuid/${uuid}`);
                setPost(response.data);
            } catch (err) {
                console.error('Błąd podczas pobierania posta:', err);
                setError('Nie udało się pobrać posta.');
            }
        };

        const fetchComments = async () => {
            try {
                const postResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/uuid/${uuid}`);
                const postId = postResponse.data.id;

                const commentsResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/${postId}/comments`);
                setComments(commentsResponse.data);
            } catch (err) {
                console.error('Błąd podczas pobierania komentarzy:', err);
                setError('Nie udało się pobrać komentarzy.');
            }
        };

        fetchPost();
        fetchComments();
    }, [uuid]);

    const handleReportPost = async () => {
        try {
            await axios.post(`https://gymsocial.pl:3001/api/posts/uuid/${post.uuid}/report`, {
                userId: user.uid,
                description: reportDescription,
                category: reportCategory,
            });
            alert('Post został zgłoszony.');
            setReportDescription('');
            setReportCategory('');
            setIsReportFormVisible(false); // Ukryj formularz po zgłoszeniu
        } catch (error) {
            console.error('Błąd podczas zgłaszania posta:', error);
            alert('Wystąpił błąd podczas zgłaszania posta.');
        }
    };

    const handleAddComment = async (e) => {
        e.preventDefault();
        if (!newComment.trim()) return;

        try {
            const postResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/uuid/${uuid}`);
            const postId = postResponse.data.id;

            await axios.post(`https://gymsocial.pl:3001/api/posts/${postId}/comment`, {
                content: newComment,
                userId: user.uid,
            });
            setNewComment('');
            const commentsResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/${postId}/comments`);
            setComments(commentsResponse.data);
        } catch (err) {
            console.error('Błąd podczas dodawania komentarza:', err);
            setError('Nie udało się dodać komentarza.');
        }
    };

    if (!post) {
        return <p>Ładowanie posta...</p>;
    }

    return (
        <div className="post">
            <div className="post-content-over">
                
                <div className="post-content">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {post.profilePic ? (
                            <img
                                src={`https://gymsocial.pl:3001/profilePic/${post.profilePic}`}
                                alt={`${post.nick}'s profile`}
                                style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
                            />
                        ) : (
                            <img
                                src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`}
                                style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }} />
                        )}
                        <Link to={`/profile/${post.userId}`} style={{ textDecoration: 'none', color: 'inherit', fontSize: '1.2em' }}>
                            <strong>{post.nick}</strong>
                        </Link>
                        <em style={{ marginLeft: '10px', fontSize: '0.9em', color: '#888' }}>{timeAgo(post.createdAt)}</em>
                    </div>
                    <h1>{post.title}</h1>
                    {post.imageUrl && (
                        <img src={`https://gymsocial.pl:3001/postsPic/${post.imageUrl}`} alt={post.title} className="post-page-image" />
                    )}
                    <p className="post-page-text">{post.content}</p>
                    <div className="post-page-actions">
                        <button onClick={post.liked ? () => handleUnlikePost(post.id, user) : () => handleLikePost(post.id, user)}>
                            <FontAwesomeIcon icon={faHeart} style={{ color: post.liked ? 'red' : 'black' }} />
                        </button>
                        <span>{post.likesCount} polubień</span>
                        <FontAwesomeIcon icon={faComment} />
                        <span>{comments.length} komentarzy</span>
                        <button onClick={() => setIsReportFormVisible(!isReportFormVisible)}>
                            {isReportFormVisible ? 'Anuluj zgłoszenie' : 'Zgłoś post'}
                        </button>
                        {isReportFormVisible && (
                            <div>
                                <h3>Zgłoś post</h3>
                                <textarea
                                    placeholder="Opisz powód zgłoszenia..."
                                    value={reportDescription}
                                    onChange={(e) => setReportDescription(e.target.value)}
                                />
                                <select value={reportCategory} onChange={(e) => setReportCategory(e.target.value)}>
                                    <option value="">Wybierz kategorię</option>
                                    <option value="spam">Spam</option>
                                    <option value="niewłaściwe_treści">Niewłaściwe treści</option>
                                    <option value="oszustwo">Oszustwo</option>
                                    <option value="inne">Inne</option>
                                </select>
                                <button onClick={handleReportPost}>Zgłoś</button>
                            </div>
                        )}
                    </div>
                    <div className="post-comments-section">
                        <h2>Komentarze</h2>
                        <form onSubmit={handleAddComment} className="post-comment-form">
                            <textarea 
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                placeholder="Dodaj komentarz..."
                                className="post-comment-form-textarea"
                            />
                            <button type="submit">Wyślij</button>
                            {error && <p className="post-error-message">{error}</p>}
                        </form>
                        <ul className="post-comments-list">
                            {comments.map((comment) => (
                                <li key={comment.id} className="post-comment-item">
                                    <strong>{comment.userNick}:</strong> {comment.content}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostPage;
import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useUser  } from '../context/UserContext';
import axios from 'axios';
import '../styles/LoginPage.css';
import CustomAlert from './CustomAlert'; // Importuj CustomAlert
import Header from './Header'; // Importuj Header

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState(''); // Stan dla komunikatu sukcesu
    const navigate = useNavigate();
    const { setUser  } = useUser ();
    const cookieConsent = localStorage.getItem('cookieConsent');
    const { user } = useUser ();

    if (user) {
        navigate('/home');
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            const response = await axios.get(`https://gymsocial.pl:3001/api/users/${user.uid}`);
            const userData = response.data;
    
            setUser ({ uid: user.uid, email: user.email, nick: userData.nick });
    
            setEmail('');
            setPassword('');
            setError('');
            setSuccessMessage('Zalogowano pomyślnie!'); // Ustaw komunikat sukcesu
            setShowAlert(true); // Pokaż alert
    
            // Użyj setTimeout, aby opóźnić nawigację
            setTimeout(() => {
                navigate('/home'); // Przekierowanie do /home
            }, 2000); // Opóźnienie 2 sekundy (2000 ms)
        } catch (err) {
            setError(err.message);
            setShowAlert(true); // Pokaż alert w przypadku błędu
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setSuccessMessage(''); // Resetuj komunikat sukcesu
    };

    if (!cookieConsent) {
        return (
            <div>
                <h2>Musisz zaakceptować pliki cookie, aby się zalogować.</h2>
                <p>Proszę zaakceptować pliki cookie, aby kontynuować.</p>
            </div>
        );
    }
        return (
            <div className="login-page" style={{ marginTop: '80px' }}>
                <Header /> 
                <form onSubmit={handleLogin}>
                    <h2>Logowanie</h2>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Hasło"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Zaloguj się</button>
                    {error && <p className="error-message">{error}</p>}
                </form>
                <p className="register-prompt">
                    Nie masz konta? <span onClick={() => navigate('/register')} className="register-link">Zarejestruj się</span>
                </p>
                <p className="forgot-password">
                    <span onClick={() => navigate('/reset-password')} className="reset-link">Zapomniałeś hasła?</span>
                </p>
            </div>
        );
    }

export default LoginPage;
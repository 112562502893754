import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import RegisterPage from './components/RegisterPage';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import CreatePost from './components/CreatePost';
import PrivateRoute from './components/PrivateRoute';
import CookieConsent from './components/CookieConsent';
import { UserProvider } from './context/UserContext';
import SettingsPage from './components/SettingsPage';
import ProfilePage from './components/ProfilePage';
import Layout from './components/Layout';
import Messages from './components/Messages'; // Importuj komponent Messages
import ChatPage from './components/ChatPage'; // Importuj komponent ChatPage
import NotificationsPage from './components/NotificationsPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import PostPage from './components/PostPage';


const App = () => {
  return (
    <UserProvider>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} /> 
              <Route path="/home" element={<PrivateRoute><Layout><HomePage /></Layout></PrivateRoute>} />
              <Route path="/create-post" element={<PrivateRoute><Layout><CreatePost /></Layout></PrivateRoute>} />
              <Route path="/settings" element={<PrivateRoute><Layout><SettingsPage /></Layout></PrivateRoute>} /> 
              <Route path="/profile/:uid" element={<PrivateRoute><Layout><ProfilePage /></Layout></PrivateRoute>} />
              <Route path="/messages" element={<PrivateRoute><Layout><Messages /></Layout></PrivateRoute>} />
              <Route path="/messages/:conversationId" element={<PrivateRoute><Layout><ChatPage /></Layout></PrivateRoute>} /> {/* Zmiana tutaj */}
              <Route path="/notifications" element={<PrivateRoute><Layout><NotificationsPage /></Layout></PrivateRoute>} />
              <Route path="/posts/:uuid" element={<PrivateRoute><Layout><PostPage /></Layout></PrivateRoute>} />
          </Routes>
          <CookieConsent />
      </BrowserRouter>
    </UserProvider>
  );
};

export default App;
// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBv2xxaX-C4J8pEpV9BUSmudc27C4mxgwQ",
    authDomain: "gymsocial-bcda6.firebaseapp.com",
    projectId: "gymsocial-bcda6",
    storageBucket: "gymsocial-bcda6.firebasestorage.app",
    messagingSenderId: "204051293449",
    appId: "1:204051293449:web:a7cb4d041fb1a19d769fd8",
    measurementId: "G-155BG8NQ0H"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
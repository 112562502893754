import React, { useEffect, useState, useRef } from 'react'
import { useUser  } from '../context/UserContext';
import axios from 'axios';
import '../styles/SettingsPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser  } from '@fortawesome/free-solid-svg-icons';

const SettingsPage = () => {
    const { user, setUser  } = useUser ();
    const [nick, setNick] = useState(user ? user.nick : '');
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');
    const [userData, setUserData] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [previewImage, setPreviewImage] = useState(null); // Nowy stan do podglądu nowego zdjęcia

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/users/${user.uid}`);
                setUserData(response.data);
            } catch (error) {
                setError('Błąd podczas ładowania danych użytkownika.');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [user.uid]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        
        // Ustaw podgląd nowego zdjęcia
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result); // Ustaw podgląd na URL nowego zdjęcia
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewImage(null); // Resetuj podgląd, jeśli nie ma pliku
        }
    };

    const handleSaveSettings = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('image', image);
            formData.append('nick', nick);
    
            // Sprawdź, czy istnieje stare zdjęcie profilowe
            if (userData && userData.profilePic) {
                // Wywołaj API do usunięcia starego zdjęcia
                await axios.delete(`https://gymsocial.pl:3001/api/users/${user.uid}/profilePic`, {
                    data: { profilePic: userData.profilePic }
                });
            }
    
            // Zapisz nowe zdjęcie i nick
            await axios.put(`https://gymsocial.pl:3001/api/users/${user.uid}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            setUser ((prevUser ) => ({ ...prevUser , nick }));
            setSuccessMessage('Ustawienia zostały zapisane pomyślnie!');
            setError('');
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setError(err.response.data.message); // Ustaw komunikat z odpowiedzi serwera
            } else {
                setError('Wystąpił błąd podczas zapisywania ustawień.');
            }
            setSuccessMessage('');
        }
    };

    return (
        <div className="settings-page">
            <h1>Ustawienia</h1>
            <form onSubmit={handleSaveSettings} className="settings-form">
                <div className="form-group">
                <label>Zdjęcie profilowe:</label>
                    <div className="image-preview-container" onClick={() => document.getElementById('imageInput').click()}>
                        {previewImage ? ( // Użyj podglądu nowego zdjęcia, jeśli jest dostępne
                            <img 
                                src={previewImage} 
                                alt="Podgląd nowego zdjęcia" 
                                className="settings-page-image-preview" 
                            />
                        ) : userData && userData.profilePic ? ( // Sprawdź, czy istnieje zdjęcie profilowe
                            <img 
                                src={`https://gymsocial.pl:3001/profilePic/${userData.profilePic}`} 
                                alt={`${userData.nick}'s profile`} 
                                className="settings-page-image-preview" 
                            />
                        ) : (
                            <img
                                src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`}
                                className="settings-page-image-preview" />
                        )}
                        <input
                            type="file"
                            accept="image/*"
                            id="imageInput"
                            style={{ display: 'none' }} // Ukryj standardowe pole wyboru pliku
                            onChange={handleImageChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Nick:</label>
                    <input
                        type="text"
                        value={nick}
                        onChange={(e) => setNick(e.target.value)}
                        required
                    />
                </div>
                
                <button type="submit" className="save-button">Zapisz zmiany</button>
                {error && <p className="error-message">{error}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
            </form>
        </div>
    );
};

export default SettingsPage;
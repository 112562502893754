import React, { useEffect, useState } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div style={styles.container}>
            <p>Używamy plików cookie, aby poprawić jakość naszej strony. Akceptując, zgadzasz się na ich użycie.</p>
            <button onClick={handleAccept} style={styles.button}>Akceptuję</button>
        </div>
    );
};

const styles = {
    container: {
        position: 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
        backgroundColor: '#fff',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
        zIndex: 1000,
    },
    button: {
        marginLeft: '10px',
        padding: '5px 10px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

export default CookieConsent;